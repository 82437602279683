import React from "react";
import { Div } from "./top-bar.style.component";
import Countdown from "../countdown/countdown.component";

export class TopBar extends React.Component {
  private parameters: any = null;
  private closeTopBar = false;
  private showCountdown = false;
  private isOnCheckoutRoute = location.pathname.indexOf("cotizar") > -1;

  componentDidMount() {
    window["env"].parameters.then((parameters) => {
      this.parameters = parameters;

      if (
        parameters.parameterSettings.countdown.countdownStart &&
        parameters.parameterSettings.countdown.countdownEnd
      ) {
        const now = new Date();
        const countdownEnd = new Date(
          parameters.parameterSettings.countdown.countdownEnd
        );
        const countdownStart = new Date(
          parameters.parameterSettings.countdown.countdownStart
        );

        this.showCountdown = now >= countdownStart && now <= countdownEnd;
      }

      this.forceUpdate();
    });
  }

  scrollToSpvQuote = () => {
    const spvQuoteElement = document.getElementById("spv-quote-latest-home");

    if (spvQuoteElement) {
      spvQuoteElement.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = "/";
    }
  };

  render() {
    if (
      this.closeTopBar ||
      this.isOnCheckoutRoute ||
      (!this.parameters?.parameterSettings?.topBar?.copy &&
        !this.parameters?.parameterSettings?.countdown?.countdownEnd)
    ) {
      return <></>;
    }

    return (
      <Div
        className="container-fluid top-bar"
        onClick={this.scrollToSpvQuote}
        style={{ cursor: "pointer" }}
      >
        <div className="row">
          <div className="col-xs-11 d-lg-flex middle-lg">
            <p
              className="mt-md-0 text-copy Gotham mb-0"
              dangerouslySetInnerHTML={{
                __html: this.parameters?.parameterSettings?.topBar?.copy,
              }}
            ></p>
            {this.showCountdown && (
              <Countdown
                endDate={
                  this.parameters.parameterSettings.countdown.countdownEnd
                }
              />
            )}
          </div>

          <div className="col-xs-1 d-flex top-xs middle-md end-xs ml-auto aling-center">
            <button
              className="btn text-white p-0 mt-md-0"
              type="button"
              onClick={() => this.onCloseTopBar()}
            >
              X
            </button>
          </div>
        </div>
      </Div>
    );
  }

  onCloseTopBar() {
    this.closeTopBar = true;
    this.forceUpdate();
  }
}
