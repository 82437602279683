import styled from "styled-components";

export const Header = styled.header`
  height: auto;
  width: 100%;
  z-index: 2000;
  border-bottom: 2px solid #2855a0;

  &.header-fixed {
    top: 0;
    position: fixed;
    background-color: #2855a0;
    border-bottom: 2px solid white;
  }
  &.header-absolute {
    position: relative;
  }

  .dropdown-toggle {
    color: #${(props) => props.linksColor};
  }
  .btn-mobile {
    border-radius: 10px;
    background: #2855a0;
    padding: 0px 9px;
  }
  .btn-mobile-scroll {
    border-radius: 10px;
    background: white;
    padding: 0px 9px;
    color: #2855a0 !important;
  }
  .p-mobile {
    padding: 10px 0px;
  }
  .bg-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .h-navbar {
    height: 85px;
  }
  .w-header {
    width: 50%;
  }
  .w-header-scroll {
    width: 65%;
  }
  .w-header-ec {
    width: 69%;
  }
  .w-header-scroll-ec {
    width: 75%;
  }
  @media (min-width: 992px) {
    .logo-page {
      width: 180px !important;
      height: 95px !important;
    }

    .p-mobile {
      padding: 0px !important;
    }
    &.header-absolute {
      position: absolute;
    }
    .header-relative {
      position: relative;
    }
    .h-navbar {
      height: 96px;
    }
  }
  @media (max-width: 1290px) {
    .f-12-ec * {
      font-size: 12px !important;
    }
    .f-10-scl-ec * {
      font-size: 10px !important;
    }
    .w-header {
      width: auto;
    }
    .w-header-scroll {
      width: auto;
    }
    .w-header-ec {
      width: auto;
    }
    .w-header-scroll-ec {
      width: auto;
    }
    .btn-responsive img {
      width: 34px;
      height: 34px;
    }
  }
  @media (min-width: 1290px) and (max-width: 1390px) {
    .w-header {
      width: auto;
    }
    .w-header-ec {
      width: auto;
    }
  }

  @media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    .openbtn {
      padding-bottom: 2px;
    }
    .div-button-wpp {
      padding-top: 2px;
    }
    .div-button-wpp a img {
      height: 34px;
      width: 34px;
    }
  }
`;

export const Button = styled.button`
  color: #2855a0;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  line-height: 1.188rem;
  border-radius: 50px;
  background-color: white;
`;

export const Section = styled.section`
  height: auto;
  width: 100%;
  z-index: 2000;
  border-bottom: 2px solid #2855a0;

  &.header-fixed {
    top: 0;
    position: fixed;
    background-color: #2855a0;
    border-bottom: 2px solid white;
  }

  @media (min-width: 992px) {
    .header-relative {
      position: relative;
    }
  }
`;
